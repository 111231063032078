import { type ActionFunctionArgs, useActionData, Form } from 'react-router-dom'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { login, type UseActionData } from '@trinity/utils'

export async function action({ request }: ActionFunctionArgs) {
  return await login(request, 'dealer', '/login')
}

export function Login() {
  const actionData = useActionData() as UseActionData<typeof action>

  return (
    <Stack direction='row'>
      <Box
        flex={1}
        sx={{
          backgroundImage: `url(${IMAGE})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top 0 right 0',
          backgroundSize: 1500,
          height: window.innerHeight,
        }}
        display={{ xs: 'none', laptop: 'block' }}
      />
      <Stack spacing={{ mobile: 20, laptop: 40 }} flex={1} alignItems='center' justifyContent='center'>
        <Form method='POST'>
          <Stack spacing={4} minWidth={400}>
            <Typography align='center' variant='h1'>
              Welcome
            </Typography>
            <TextField required variant='filled' name='username' label='Username' />
            <TextField required variant='filled' type='password' name='password' label='Password' />
            {actionData?.error && (
              <Typography color='error' align='center'>
                {actionData.data}
              </Typography>
            )}
            <Button type='submit'>Log In</Button>
          </Stack>
        </Form>
      </Stack>
    </Stack>
  )
}

const IMAGE =
  'https://trinityapparel.picarioxpo.com/Jacket_102_Ghost.pfs?1=1&p.tn=Z2-49150336.jpg%2CL1-47134316.jpg&p.tc=0.7%2C0.7&p.c=%2C%2C35495a%2C2f456b&1=1&width=2000'
