import {
  Link as MuiLink,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Box,
} from '@mui/material'
import { AccountCircle, Assignment, FolderOpen, Memory } from '@mui/icons-material'
import {
  type ActionFunctionArgs,
  Link,
  type LoaderFunctionArgs,
  useLoaderData,
  useSearchParams,
} from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import { Bulletins, DealerRankChip, OrderStatus, Pagination, Search, useGlobalState } from '@trinity/components'
import {
  type UseLoaderData,
  formatDate,
  getOrders,
  getSearches,
  viewOrderUrl,
  getBulletins,
  getMetrics,
  getGarmentCount,
  parseFormData,
  getDealerRank,
} from '@trinity/utils'

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url)
  const scope = url.searchParams.get('scope') ?? 'all'
  const page = url.searchParams.get('page') ?? 1
  const perPage = url.searchParams.get('perPage') ?? 100
  const sortOrder = url.searchParams.get('sort') ?? 'desc'
  const params = { scope, page: Number(page), per_page: Number(perPage), sortOrder }

  const [orders, bulletins, metrics, dealerStats] = await Promise.all([
    getOrders(params),
    getBulletins(),
    getMetrics(),
    getDealerRank(),
  ])

  return { ...orders, bulletins, metrics, dealerStats }
}

export async function action({ request }: ActionFunctionArgs) {
  const data = await parseFormData(request)

  if (data.search) {
    const suggestions = await getSearches({ q: String(data.search) })
    return { suggestions }
  }

  return null
}

export function Orders() {
  const { onMobile, user } = useGlobalState()
  const loaderData = useLoaderData() as UseLoaderData<typeof loader>
  const [params] = useSearchParams()
  const scope = params.get('scope')

  if (!loaderData) return null

  return (
    <Stack alignItems='center' spacing={8} pt={14}>
      {user.permissions.nav.includes('can_view_price_list') && (
        <DealerRankChip
          rank={loaderData.dealerStats.rank}
          revenue={loaderData.dealerStats.revenue}
          percentile={loaderData.dealerStats.revenueTrend ?? 0}
        />
      )}
      <Box width={{ mobile: 1, laptop: 2 / 5 }}>
        <Search<TrinityAPI.SearchType>
          isHome
          name='search'
          placeholder={onMobile ? 'Search' : 'Clients, orders, garments, collections, or fabrics'}
          offset={35}
        >
          {({ suggestion, searchTerm }) => <AutocompleteSuggestion suggestion={suggestion} value={searchTerm} />}
        </Search>
      </Box>
      {!onMobile && <Bulletins bulletins={loaderData.bulletins} />}
      <Stack
        spacing={{ mobile: 3, laptop: 6 }}
        justifyContent='center'
        alignItems='center'
        pt={{ mobile: 0, laptop: 10 }}
        width={{ mobile: 1, tablet: 3 / 4 }}
      >
        <OrderStatus>
          <OrderStatus.Tabs delays={loaderData.metrics.delays} />
          <OrderStatus.Filters />
          {loaderData.orders.length === 0 && <OrderStatus.NoOrdersFound />}
          <Stack spacing={1} width={1}>
            {loaderData.orders.map(order => (
              <OrderStatus.Accordion key={order.id} order={order}>
                <Typography
                  noWrap
                  variant='h6'
                  sx={{
                    pl: { xs: 0.5, sm: 2 },
                    flex: 2,
                    maxWidth: { xs: 2 / 3, sm: '100%' },
                  }}
                >
                  {order.customer.name}
                </Typography>
                <Typography variant='body1' sx={{ flex: 1 }}>
                  {scope === 'saved' ? 'Started ' : ''}
                  {formatDate(order.orderedAt ?? order.createdAt)}
                </Typography>
                {!onMobile && (
                  <Typography noWrap align='right' variant='subtitle1' sx={{ flex: 2.5 }}>
                    {getGarmentCount(order.garments)}
                  </Typography>
                )}
              </OrderStatus.Accordion>
            ))}
          </Stack>
        </OrderStatus>
        <Pagination total={loaderData.totalOrders} pageSizeOptions={[100, 200, 300]} />
      </Stack>
    </Stack>
  )
}

interface AutocompleteSuggestionProps {
  suggestion: TrinityAPI.SearchType
  value: string
}

function AutocompleteSuggestion({ suggestion, value }: AutocompleteSuggestionProps) {
  const LinkComponent = ['order', 'garment'].includes(suggestion.type) ? MuiLink : Link
  const url = getUrl(suggestion)

  return (
    <ListItem disablePadding key={suggestion.id}>
      <ListItemButton component={LinkComponent} href={url} to={url}>
        <ListItemIcon>
          <SearchIcon type={suggestion.type} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: 'body1' }}>
          <Highlighter searchWords={[value]} textToHighlight={suggestion.name} highlightTag='strong' />
        </ListItemText>
        {suggestion.description && (
          <ListItemText primaryTypographyProps={{ variant: 'body2', noWrap: true, sx: { maxWidth: 120 } }}>
            <Highlighter searchWords={[value]} textToHighlight={suggestion.description} highlightTag='strong' />
          </ListItemText>
        )}
      </ListItemButton>
    </ListItem>
  )
}

function SearchIcon({ type }: { type: TrinityAPI.SearchType['type'] }) {
  switch (type) {
    case 'fabric':
      return <Memory />
    case 'collection':
      return <FolderOpen />
    case 'customer':
      return <AccountCircle />
    case 'order':
    case 'garment':
      return <Assignment />
  }
}

function getUrl(suggestion: TrinityAPI.SearchType) {
  switch (suggestion.type) {
    case 'fabric':
      return `/fabric-explorer/fabrics/${suggestion.id}`
    case 'collection':
      return `/fabric-explorer/collections/${suggestion.id}`
    case 'customer':
      return `/clients/${suggestion.id}`
    case 'order':
      return viewOrderUrl(suggestion.id)
    case 'garment':
      return viewOrderUrl(suggestion.dealerOrderId ?? 0)
  }
}
